import { Form, Image } from 'antd-mobile'
import imgQiyeweixin from '../assets/qywx.jpeg'

function Index() {
  return (
    <div>
      <h3 style={{ marginLeft: 16 }}>德世科技 - 中国电信授权代理，请放心购买！</h3>
      <Form layout="horizontal">
        <Form.Item label="地址">江苏省常州市武进区科教城创研港3C801</Form.Item>
        <Form.Item label="渠道名称">浙江温州德之和网厅代理店</Form.Item>
        <Form.Item label="渠道编码">3303002416560</Form.Item>
        <Form.Item label="企业微信">
          <Image src={imgQiyeweixin} style={{ width: '60%' }} />
        </Form.Item>
      </Form>
    </div>
  )
}

export default Index
