// types
const types = {
  OPEN_NAME: 'OPEN_NAME',
  OPEN_ID_CARD: 'OPEN_ID_CARD',
  RECV_NAME: 'RECV_NAME',
  RECV_PHONE: 'RECV_PHONE',
  RECV_ADDR_PROVINCE: 'RECV_ADDR_PROVINCE',
  RECV_ADDR_CITY: 'RECV_ADDR_CITY',
  RECV_ADDR_DISTRICT: 'RECV_ADDR_DISTRICT',
  RECV_ADDR_DETAIL: 'RECV_ADDR_DETAIL',
  PROD_LIST: 'PROD_LIST',
  CLICK_ID: 'CLICK_ID',
  BANNER_IMAGES: 'BANNER_IMAGES',
  CHECKED: 'CHECKED',
}

// state
const initialState = {
  openName: '',
  openIdCard: '',
  recvName: '',
  recvPhone: '',
  recvAddrProvince: '',
  recvAddrCity: '',
  recvAddrDistrict: '',
  recvAddrDetail: '',
  prodList: [],
  clickId: '',
  bannerImages: [],
  checked: false,
}

// action creators
const actions = {
  setOpenName: d => ({
    type: types.OPEN_NAME,
    payload: d,
  }),
  setOpenIdCard: d => ({
    type: types.OPEN_ID_CARD,
    payload: d,
  }),
  setRecvName: d => ({
    type: types.RECV_NAME,
    payload: d,
  }),
  setRecvPhone: d => ({
    type: types.RECV_PHONE,
    payload: d,
  }),
  setRecvAddrProvince: d => ({
    type: types.RECV_ADDR_PROVINCE,
    payload: d,
  }),
  setRecvAddrCity: d => ({
    type: types.RECV_ADDR_CITY,
    payload: d,
  }),
  setRecvAddrDistrict: d => ({
    type: types.RECV_ADDR_DISTRICT,
    payload: d,
  }),
  setRecvAddrDetail: d => ({
    type: types.RECV_ADDR_DETAIL,
    payload: d,
  }),
  setProdList: d => ({
    type: types.PROD_LIST,
    payload: d,
  }),
  setClickId: d => ({
    type: types.CLICK_ID,
    payload: d,
  }),
  setBannerImages: d => ({
    type: types.BANNER_IMAGES,
    payload: d,
  }),
  setChecked: d => ({
    type: types.CHECKED,
    payload: d,
  }),
}

// reducers
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_NAME:
      return { ...state, openName: action.payload }
    case types.OPEN_ID_CARD:
      return { ...state, openIdCard: action.payload }
    case types.RECV_NAME:
      return { ...state, recvName: action.payload }
    case types.RECV_PHONE:
      return { ...state, recvPhone: action.payload }
    case types.RECV_ADDR_PROVINCE:
      return { ...state, recvAddrProvince: action.payload }
    case types.RECV_ADDR_CITY:
      return { ...state, recvAddrCity: action.payload }
    case types.RECV_ADDR_DISTRICT:
      return { ...state, recvAddrDistrict: action.payload }
    case types.RECV_ADDR_DETAIL:
      return { ...state, recvAddrDetail: action.payload }
    case types.PROD_LIST:
      return { ...state, prodList: action.payload }
    case types.CLICK_ID:
      return { ...state, clickId: action.payload }
    case types.BANNER_IMAGES:
      return { ...state, bannerImages: action.payload }
    case types.CHECKED:
      return { ...state, checked: action.payload }
    default:
      return state
  }
}

// export
export { initialState, types, actions, reducers }
