import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as appActions } from '../redux/app'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Cascader, Checkbox, Form, Image, Input, Mask, NavBar, Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import areas from '../data/areas'
import imgBtn from '../assets/btn.png'
import { apiOrder, apiProd } from '../api/order'
import { HTTP_URL } from '../infra/constants'
import globals from '../data/globals'
import { debug, formEvent } from '../api/douyin'
import { randStr } from '../util/str'
import ajaxApi from '../infra/axios-settings'

function Index() {
  const bannerImages = useSelector(state => state.app.bannerImages)
  const dispatch = useDispatch()

  const [prod, setProd] = useState({})

  const [visible, setVisible] = useState(false)

  const [checked, setChecked] = useState(globals.checked)
  const [protocolVisible, setProtocolVisible] = useState(false)
  const [noticeVisible, setNoticeVisible] = useState(false)

  // form
  const [recvName, setRecvName] = useState('')
  const [recvPhone, setRecvPhone] = useState('')
  const [addrList, setAddrList] = useState([])
  const [recvAddrDetail, setRecvAddrDetail] = useState('')
  const [openName, setOpenName] = useState('')
  const [openIdCard, setOpenIdCard] = useState('')

  const [submitRecvPhone, setSubmitRecvPhone] = useState([])

  // nav
  const navigate = useNavigate()
  const [params] = useSearchParams()

  useEffect(() => {
    location()
    onQueryProd()
  }, [])

  function location() {
    ajaxApi.get('/api/v1/tool/ip2region').then(resp => {
      console.log(resp)
      const p0 = resp.data.data.p
      const c0 = resp.data.data.c

      const p = areas.find(it => it.value.startsWith(p0.substring(0, 2)))
      if (p) {
        const c = (p.children || []).find(it => c0.includes(it.value.substring(0, 2)))
        if (c) {
          setAddrList([p.value, c.value, c.children[0].value])
        } else {
          setAddrList([p.value, p.children[0].value, p.children[0].children[0].value])
        }
      } else {
        setAddrList([areas[0].value, areas[0].children[0].value, areas[0].children[0].children[0].value])
      }
    })
  }

  const onQueryProd = () => {
    apiProd().then(resp => {
      if (!resp.data.data) return
      console.log(resp.data.data)
      const prodList = (resp.data.data || []).map(it => ({ ...it, prodImages: JSON.parse(it.prodImages) }))
      console.log(prodList)
      globals.prodList = prodList

      const prodId = params.get('prodId')
      const prod = globals.prodList.find(it => it.prodId === prodId)
      setProd(prod)
      console.log(prod)

      dispatch(appActions.setBannerImages([prod.prodImages[0]]))
    })
  }

  const onFinish = () => {
    if (openName.length < 2 || recvName.length < 2) {
      Toast.show({
        icon: 'fail',
        content: '姓名不能少于2位',
      })
      return
    }
    if (
      (openIdCard.length !== 15 && openIdCard.length !== 18) ||
      !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(openIdCard)
    ) {
      Toast.show({
        icon: 'fail',
        content: '身份证号码疑似有误',
      })
      return
    }
    if (!/^1[3-9]\d{9}$/.test(recvPhone)) {
      Toast.show({
        icon: 'fail',
        content: '手机号码疑似有误',
      })
      return
    }
    if (addrList.length !== 3) {
      Toast.show({
        icon: 'fail',
        content: '省市区县请选择完整',
      })
      return
    }
    if (recvAddrDetail.length < 4) {
      Toast.show({
        icon: 'fail',
        content: '详细地址不少于4个字符',
      })
      return
    }
    if (!checked) {
      Toast.show({
        icon: 'fail',
        content: '请阅读并同意用户协议',
      })
      return
    }

    if (submitRecvPhone.includes(recvPhone)) {
      Toast.show({
        icon: 'fail',
        content: '每个号码只能办理一张，请勿重复提交',
      })
      return
    }
    if (!submitRecvPhone.includes(recvPhone)) {
      setSubmitRecvPhone([...submitRecvPhone, recvPhone])
    }

    const now = dayjs().format('YYYY-MM-DD HH:mm:ss')
    const salePerson = window.localStorage.getItem('simId') || ''

    const payload = {
      orderId: `H5M${dayjs().format('YYYYMMDDHHmmssSSS')}${randStr(12).toUpperCase()}`,
      totalFee: 0.0,
      amount: 1,
      shopId: 'ZH5MALL',
      prodId: prod.prodId,
      prodName: prod.prodName,
      prodCode: prod.prodCode,
      recvName,
      recvPhone,
      recvAddrProvince: addrList[0],
      recvAddrCity: addrList[1],
      recvAddrDistrict: addrList[2],
      recvAddrDetail: `${addrList.join('')}${recvAddrDetail}`,
      openName,
      openIdCard,
      salePerson: (salePerson || '') === '' ? '' : salePerson || '',
      createTime: now,
      updateTime: now,
      payTime: now,
    }
    debug({ info: JSON.stringify(payload) })

    apiOrder(payload)
      .then(resp => {
        if (resp.data && resp.data.code && resp.data.code === '1') {
          Toast.show({
            icon: 'success',
            content: '已成功提交',
          })
        }
        if (resp.data && resp.data.code && resp.data.code === '0') {
          Toast.show({
            icon: 'fail',
            content: resp.data.info,
          })
        }
        // 抖音 formEvent
        formEvent({
          clickId: window.localStorage.getItem('clickid') || '',
        })
      })
      .catch(err => {
        Toast.show({
          icon: 'fail',
          content: err.toString(),
        })
      })
  }

  const form = (
    <>
      <Form
        layout="horizontal"
        footer={
          <>
            <div style={{ marginBottom: 16 }}>
              <Checkbox
                checked={checked}
                onChange={value => {
                  setChecked(value)
                  globals.checked = value
                }}
              >
                我已阅读并同意
                <a href="#" style={{ color: '#fa5252' }} onClick={() => setProtocolVisible(true)}>
                  《入网协议》
                </a>
                、
                <a href="#" style={{ color: '#fa5252' }} onClick={() => setNoticeVisible(true)}>
                  《关于客户个人信息收集、使用规则的公告》
                </a>
              </Checkbox>
              <Mask
                visible={protocolVisible}
                disableBodyScroll={true}
                onMaskClick={() => setProtocolVisible(false)}
                opacity={90}
                color="white"
              >
                <div
                  style={{ padding: 6, height: '100vh', overflowY: 'scroll' }}
                  onClick={() => setProtocolVisible(false)}
                >
                  <p>中国电信客户服务协议</p>
                  本协议由客户与中国电信有限公司销售分公司 (以下简称公司)签署。 一、声明及承诺
                  1.客户或其代理人在签署前应当认真阅读、充分理解本协议、办理各类业务的单独协议或业务受理单以及相关的业务规则，特别是与客户约定的限制或免除责任的条款以及其他以粗体形式标识的重要条款。如客户对协议有任何疑问，可提出咨询及解释的申请。一旦客户或其代理人签署本协议、业务的单独协议或业务受理单，即视为客户已理解并接受本协议、单独协议或业务受理单的全部条款。
                  2.客户应具有完全的民事行为能力，客户为无民事行为能力人或限制民事行为能力人的，请在法定监护人的陪同下阅读本协议、各类业务的单独协议或业务受理单、相关业务规则，由其监护人代理客户签署本协议。
                  3.客户办理各类业务所签署的单独协议或业务受理单、相关业务规则等均为本协议的补充协议，与本协议不可分割且.具有同等法律效力。
                  4.本协议内容若有变更，公司将提前一个月通知客户：若客户继续使用公司服务的，视为接受修改后的协议内容。若客户不同意修改后的协议内容，应向公司申请终止服务，本合同自动终止。
                  5.客户使用公司提供的服务，应当按照我国法律、法规和政府部门的规定以及公司的要求完成实名认证，并有义务保持所提供信息的完整性、真实性及有效性。在客户未完成实名认证前，客户使用公司服务可能会受到限制，公司有权解除协议，收回号码,终止提供服务。
                  二、服务内容及服务承诺
                  1.客户愿意使用公司提供的号码，并接受公司提供的电信服务公司愿意在现有技术条件下、网络覆盖范围内以及签订有漫游协议的电信运营商的网络覆盖范围内为客户提供电信服务，具体服务内容由客户与公司通过签署单独协议、业务受理单或其他方式另行约定。
                  2.客户可以自主选择是否订购第三方服务商提供的由公司代收服务费的增值电信业务(以下简称”代收费业务”)
                  3.公司提供的电信服务应当符合法律法规的规定及政府主管部广发布的相关标准;客户应遵守法律法规的规定使用公司提供的服务。
                  4.公司将通过营业厅、门户网站、10086 热线等渠道公示服务项目、服务时限、服务范围及资费标准等内容。
                  5.公司向客户提供 10086
                  热线等便捷的业务办理和客户投诉受理渠道，公司应在接到客户投诉之日起48小时内回复。
                  6.因客户账户余额不足等原因，可能导致暂停服务的，公司将通过短信、电话或其它方式之一提醒客户。客户知悉，由于通知存在一定滞后性，公司通知客户的账户余额与客户实际余额可能不致。公司未提醒或未及时提醒导致客户欠费的，不减免客户支付欠费的责任。
                  7.为及时了解公司业务、优惠等信息，在服务存续期间客户将接收公司发送的业务、服务及优惠信息。具体业务规则公司可通过门户网站、营业厅予以公示或在业务受理单中予以明确。若客户不再需要该等信息的，可要求公司停止发。
                  三、入网登记
                  1.客户使用公司号码并接受公司提供的电信服务，需办理入网登记手续。客户办理入网登记手续时，应当按照《电话用户真实身份信息登记规定》(工业和信息化部今第
                  25
                  号)向公司出示有效证件，提供真实身份信息。客户委托他人办理入网手续的，受托人应当出示客户和受托人的有效证件，并提供客户和受托人的真实身份信息。
                  2.客户及其受托人拒绝出示有效证件，拒绝提供其证件上所记载的身份信息，冒用他人的证件，或者使用伪造、变造的证件的,公司有权不为其办理入网手续。
                  3.使用同一身份信息办理多个号码的，均视为同一客户。由干公司号码数量有限，客户名下的号码数量不得超过公司规定的限额。客户现在或曾经持有的任一客户号码发生欠费的，客户应缴清欠费才得办理新的号码入网手续。
                  4.如客户入网登记资料不详或发生变更，应及时到公司营业厅办理资料完善或变更手续，如因客户原因提供的资料不详、不实或变更后未及时办理变更手续等原因所造成的后果由客户自行承担。
                  5.客户入网后自动获取客户初始服务密码的，应立即修改初始服务密码并妥善保管。客户因密码保管不善造成的后果由客户自行承担，但公司有义务配合客户及协助公安机关调查相关情况。为方便办理业务，公司可根据客户申请以短信等方式提供随机密码，该密码可作为办理业务的临时凭证。客户使用服务密码或随机密码，以及通过人像比对、联网查验等方式办理业务的行为均被视为客户或客户授权的行为。
                  6.客户通过其号码发生的通信及办理业务的行为均被视为客户或客户授权的行为。客户应妥善保管
                  SIM卡，如客户发现
                  SIM卡丢失、有他人冒用或盗用其号码、密码、或有任何其他未经合法授权的情形时，应立即以有效方式通知公司，要求公司暂停服务。客户因
                  SIM卡丢失或损坏等原因需办理补卡手续或换卡手续时，客户可凭在乙方登记的有效证件原件到公司营业网点办理或通过公司指定的其他方式办理，公司可以按规定收取费用。
                  7.公司可重新启用其他客户曾使用过的号码，但该号码自原客户销户之日起至重新启用之日止的间隔不少于电信主管部门规定的期限。原使用该号码的客户将该号码留作金融机构业务、非金融机构支付业务或其它行业业务
                  (以下统称为“第三方业务”)
                  的联系电话且在销户前未予变更时，该号码可能会收到发给原使用该号码的客户的通知信息。原使用该号码的客户将该号码用作第三方业务的认证捆绑电话目在销户前未解除绑定关系时，客户可能因第三方业务提供方的限制而无法使用该号码进行对应第三方业务的认证捆绑。对此情形，在客户提出要求时，公司应协助客户与第三方业务提供方协商解决。
                  8.客户将本号码留作第三方业务的联系电话、或用作第三方业务的认证捆绑电话时，为保护资金安全，建议勿将本号码收到的密码或其他验证信息作为资金变动的凭据，否则由此导致的损失由客户自行承担。
                  四、资费及费用交纳 1.客户使用公司提供的电信服务应支付相关费用，公司按照公示的收费规则
                  (包括收费项目、资费标准、计费周期、缴费时间等)向客户收取电信服务相关费用:客户与公司有特别约定的按照特别约定执行。
                  2.客户有权要求公司提供免费的话费查询服务，公司有义务对账单墨详单进行解释。根据《电信服务规范》更公司对计费原始数据保留期限为5
                  个月 (系统产生用户话单当月起后 5 个月，不含当月)。若客户对公司收取的费用存有异议，应在异议费用发生后5
                  个月内向公司提出 (系统产生用户话单当月起后 5个月，不含当月)。公司对客户在异议话费发生后
                  5个月内提出异议的话单，应保存至异议解决为止。
                  3.客户应按时向公司交纳费用账单中记载的全部费用。客户超过约定交费日未交足费用
                  (以下称欠费)》，公司有权暂停提供服务(以下称欠费停机，并可以按照《中华人民共和国电信条例》规定，对所欠费用自交费日次日起每日加收
                  3%。(千分之三)的违约金。自欠费停机之日起60日内，如客户交清欠费和违约金的，公司应在客户交清费和违约金后的24小时内恢复暂停的服务(以下称”复机”)。欠费停机60日内客户仍未补交欠费和违约金的，公司有权终止提供电信服务
                  (即销户)，并可以通过信函、公告、委托第三方等方式追缴欠费和违约金。客户在欠费停机期间，停机当月和复机当月的月使用费
                  (套餐费)照常计收。欠费停机跨整月不收取月使用费 (套餐费)。
                  4.客户可以自行申请办理停机保号业务，停机保号期间收取停机保号费。
                  5.客户对公司收取的费用提出异议的，公司有责任进行调查、解释，经核实确属公司责任多收费用的，公司应即时将已多收的部分返还给客户，并按照法律规定予以补偿。
                  7.如果客户订购第三方服务商的代收费业务，则视同客户同意公司代第三方服务商向客户收取服务费。该服务费的资费标准由第三方服务商制订并告知客户。若客户对收取的代收费业务服务费有异议，公司应协助与第三方服务商协商解决。
                  8.客户应妥善保管自己的号码不被非法盗用，若发现通信费用异常增长，可及时拨打客服热线 10086
                  或到公司营业网点办理停机手续，并向公安机关报案，公司应积极配合客户和公安部调查相关情况。
                  9.公司在其组织的各类活动中赠送给客户的话费 (以下称馈赠金)
                  是公司赠送给客户的并限定特定使用范围的话费。馈赠金不能用于支付与公司有合作关系的信息服务提供商的信息服务费等特定费用。馈赠金的使用期限与客户充值账户话费的使用期限致，并仅限干客户所持有的本协议指定的号码使用。客户不能使用馈赠金参与公司提供的其他优惠活动。客户使用馈赠金进行的消费，公司不提供发票。在客户因各类原因最终销户时，未使用完的馈赠金额视为客户放弃，由公司另行处置。客户请注意公司的活动公告中所约定的馈赠金的特别使用范围。
                  10.公司承诺资费方案有效期为一年
                  (双方特殊约定的除外)。公司有权在有效期截止后修改、终止资费方案。如需修改、终止，公司应在有效期截止前一个月通知客户，客户可以选择修改后的资费方案或公司提供的其他资费方案，客户也可以选择在有效期截止后终止资费方案。如原资费方案无需修改、终止且客户未选择其他套餐方案或未办理终止原资费方案手续的，则原资费方案自动顺延一年，顺延次数不限。如公司修改、终止原资费方案，但客户未选择修改后的资费方案或公司提供的其他资费方案的，且未提出终止原协议的情况下公司按客户届时的标准资费计收通信费用。
                  11.公司资费方案中月费所对应的通话时长、短信条数、上网流量等电信服务原则上当月使用。特殊资费方案中涉及跨月使用的，以资费方案约定为准。12.除双方另有约定外，客户可以选择变更资费方案。
                  五、协议的变更、转让与终止
                  1.经客户与公司协商一致，客户可以将本协议项下的号码过户给第三人。客户和第三人应持各白有效身份证件全公司营业网点办理讨户手续，客户应先行结清所有口出账费用。办理过户手续时未出账费用由第三人承担:在办理变更手续之前，因使用该号码导致的交费义务及其它后果仍由客户承担。
                  2.客户可以持登记的有效身份证件到公司营业网点要求终止提供服务
                  (即销户)。除双方另有约定外，公司不应拒绝客户销户，客户应在销户前结清所有费用。根据码号管理的有关规定销户后公司可以对原号码进行重新分配。
                  3.客户将本协议号码留作第三方业务的联系电话、或用作第三方业务的认证捆绑电话时，应在申请办理本号码过户或销户前主动变更所留存的联系电话号码或解除绑定关系，客户知悉，本号码因欠费等原因导致被公司终止提供电信服务，或客户办理过户或销户前未主动变更或解除绑定关系的，号码被过户或销户后客户将无法收到相应的通知信息或可能无法使用所捆绑的第三方业务
                  4.客户在未参与赠送，销售优惠等营销活动情况下销户时，公司应为客户提供充值账户余额 (馈赠金除外)
                  转移服务，即经客户预约后，公司应将客户充值账户余额(馈赠金除外)
                  一次性转入同一归属地的加入公司网络的另一有效移动电话号码账户。客户在参与赠送、销售优惠等营销活动情况下，充值账户余额按营销活动协议约定进行处理。
                  5.对于没有约定终止期限的业务，客户有权随时向公司申请终止业务，公司提前一个月通知客户后有权终止提供该业务。
                  6.有下列情况之一的，公司有权解除协议，收回号码，终止提供服务，由此造成的后果由客户自行承担:(1) 客户
                  (包括受托人) 提供的证件或资料虚假不实，(2)利用公司提供的服务从事违法犯罪活动:(3)
                  利用公司提供的服务从事损害违背公序良俗的活动;(4)公司收到国家有关部门通知要求停止向客户提供通信服务，(5客户欠费满60日仍未交纳费用:(6)
                  法律法规规定或者双方约定的其他情形。 六、客户信息保护及授权
                  1.客户的通信自由和通信秘密不受侵犯，公司对客户的客户资料和通信信息负有保密义务。但根据法律、行政法规有关规定，司法、行政机关依法要求公司提供协助和配合，公司给予协助和配合的，不构成违反保密义务。在切实做好用户资料保密的前提下，为更好的为客户提供服务，在保障客户权益的前提下，公司可以合理使用客户的客户资料。
                  2.客户个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别客户身份或者反映客户活动情况的各种信息。公司收集、使用客户个人信息，应当遵循合法、正当、必要的原则。
                  3.客户理解并同意，公司及其关联公司可以通过业务受理系统登记、纸质返档，通过网络接收、读取并记录等方式，以提供电信服务为目的，在业务活动中收集、使用客户提供的和客户使用服务过程中形成的个人信息。公司有权依法对包含客户在内的整体用户数据进行分析并加以利用。未经客户同意，公司不向除公司关联公司外的第三方提供客户个人信息。客户可以通过营业厅或公司指定的其他渠道，对其个人信息进行查询、更正。
                  4.公司应严格按照《网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》里工业和信息化部令第
                  24 号)立《电话用户真实身份信息登记规定》(工业和信息化部令第 25 号)
                  等法律法规、规定的相关要求，对其在提供服务过程中收集、使用的客户个人信息履行保护义务。
                  5.客户不得利用公司提供的服务从事违法犯罪活动或违背公序良俗的活动，不得发送《中华人民共和国电信条例》和其他法律法规禁止的违法信息，不得未经接收人同意发送商业性信息，不得有发送骚扰信息、拨打骚扰电话等不当行为。客户如有上述行为，公司可以采取相应措施预防或制止，包括可以暂停或终止提供服务。
                  6.客户授权公司在客户申请入网或在网站注册时通过银行或第三方审核客户的身份和资格，并授权公司在服务过程中通过银行或第三方取得关于客户使用公司服务的相关基本信息。
                  7.客户使用本号码登陆其他支持公司服务的网站/客户端时，为方便客户使用相关服务，客户授权公司将登记信息传递给客户登陆的网站，并授权公司从客户登陆的网站获取客户使用相关服务期间提供或形成的信息。
                  七、特别提示
                  1.客户应使用取得国家入网许可并具有进网许可标志的终端设备，该终端设备应具有支持其所选服务的相应功能。
                  2.客户的积分可通过手机营业厅、网上营业厅等方式进行查询，并可通过手机营业厅等指定渠道进行积分兑换。客户的积分都有一定的有效期，请客户及时在有效期内进行积分兑换，在有效期到期前，公司将通过短信通知提醒:有效期到期后，积分将自动清零。
                  3.客户入网后可委托他人到公司营业网点办理业务
                  (公司不允许代办的业务除外)，受托人应当出示客户和受托人的有效证件。 4.对于本协议所述的通知
                  (仅针对单个特定客户的通知除外)*送公司可以通过公告、10086服务热线、账单、短信、电子邮件等方式通知客户。因客户原因造成的通知未送达或未及时送达的，公司不承担责任。公司通过受到影响地区的主要报刊、播或电视进行公告后，视为已通知客户。
                  5.客户入网或者办理业务时，公司可以复印一扫描或者拍照客户身份证件。
                  6.客户入网或者办理业务时，公司可以要求客户通过手写板等签字的电子方式签名;客户认可上述手写板签名的数字化图像为可靠、有效的签名。
                  八、争议解决
                  1.本协议项下发生的争议，双方应协商解决，协商不成的，双方可向电信管理部门申诉、向消费者协会等有关部门投诉，或通过下列第()种方式解决:
                  (1) 将争议提交 当地 仲裁委员会。 (2)向公司所在地人民法院提起诉讼。 九、其他
                  1.客户办理各类业务所签署的业务受理单、表单、协议等为本协议的补充协议，与本协议冲突部分以补充协议为准，补充协议中未约定部分以本协议为准。
                  2.公司关联公司是指公司现在或将来控制、受控制或与其处于共同控制下的任何公司告机构以及上述公司或机构的合法继承人。其中控制是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
                  3.本协议经加盖公司的业务专用章，客户签字后生效。对本协议未尽事宜，双方另行协商解决。
                </div>
              </Mask>

              <Mask
                visible={noticeVisible}
                disableBodyScroll={true}
                onMaskClick={() => setNoticeVisible(false)}
                opacity={90}
                color="white"
              >
                <div
                  style={{ padding: 6, height: '100vh', overflowY: 'scroll' }}
                  onClick={() => setNoticeVisible(false)}
                >
                  <p>
                    用户须知 尊敬的用户： 您好！欢迎您使用本页面申领电信产品。为了方便您的使用，请您仔细阅读本用户须知。
                  </p>
                  <p>一、服务条款说明</p>
                  <p>
                    我们是中国电信的经销商，本页面的产品由中国电信授权我们发布。本页面的产品均由中国电信提供，并完全按照中国电信的服务条款和隐私政策严格执行。您在点击领取本页面产品前须认真阅读所有服务条款；如您按照页面提示填写申请信息、阅读并同意本协议且完成全部申领程序后，即表示您已充分阅读、理解并接受本协议的全部内容，您应对您所提供的申请信息的真实性负责，并按照本协议约定行使权利、履行义务。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止申领程序。本协议条款是处理双方权利义务的约定依据，除非违反国家法律和强制性规定，否则始终有效。
                  </p>
                  <p>二、服务简介</p>
                  <p>
                    基于电信产品的重要性和特殊性，您在本页面申领电信产品时，您应当提供真实准确的个人资料。您进入本页面填写个人资料、点击领取本页面产品时，即表示您已同意作为我们的（潜在）用户，使用我们为您提供的服务，且同意授权我们收集您在本页面提供的个人资料，并提供给产品供应商中国电信。我们将对您提供的包括姓名、联系方式、身份证、收货地址、详细地址的个人资料进行保护，承诺不会将您在本页面提供的个人资料有意泄露、出租、出售给与您领取本产品无关的任何第三方。同时，为了让您享受到更优质的服务体验，我们可能会向您提供与您更相关的服务和产品，例如转推同类产品，如推荐流量包、宽带等产品，以代替普遍投放的推送。
                    我们将依据中国电信的相关政策对您提交的申请信息进行初步审核，并根据审核结果确认是否通过您的订单；如您的订单经审核通过的，我们会将您的订单提交中国电信，由中国电信安排发货事宜。请您理解，如您未满16周岁、提交的身份信息不真实、信息错误、地址未详尽到门牌号等，中国电信将无法发货给您；同时，我们将您的订单转交中国电信后，如您在周边地区涉嫌诈骗或者符合短期内诈骗订单判定规则，中国电信可能会将您的申请视为风险订单加以拦截，不予发货；已经发货的，可能将采取关机、停机等相关措施。请注意，如因您未提供真实准确的个人资料导致订单未审核通过，或者非因我们的原因导致产品未能发货、产品无法使用的，我们对此不承担任何责任。
                    为使您能够更便捷地查询到您领取中国电信产品的申请状态，您可以关注并登录公众号,查询更多关于我们产品的信息。如果您对于您的个人资料或者对我们的服务有任何疑问，您可以拨打10000或通过客服与我们取得联系。
                    中国电信个人信息保护与隐私条款
                    中国电信（我们）非常重视您的个人信息保护。我们严格按照《中华人民共和国网络安全法》等有关法律法规的要求，对提供服务和产品过程中收集、使用的个人信息履行保护义务。因此，我们制定了涵盖如何收集、使用、披露、分享以及存储您的个人信息的保护政策。请您在接受和使用我们服务和产品前，了解和熟悉我们的个人信息保护政策。
                  </p>
                  <p>
                    一、我们收集信息的方式与范围
                    我们提供服务时，可能会通过业务受理系统登记、投诉处理登记、网络运维分析、服务调查，以及网站、APP、软件、纸质文档等方式，收集、储存下列与您有关的信息。如果您不提供相关业务要求的信息，可能无法注册成为我们的用户或无法享受我们提供的相应服务或产品。
                    1.您提供的信息
                    您在注册、开通或使用我们的服务和产品时，向我们提供的相关个人信息，包括您的姓名（名称）、性别、年龄、住址或地址、电话号码、电子邮件、偏好、身份证件、缴费相关的银行信息以及联系人信息等。
                    2.您在使用我们服务过程中产生的信息 您使用服务时我们可能收集如下信息：
                    （1）订购信息，包括您订购使用我们的电信通信、宽带接入、家庭电视及其他增值服务时产生的记录，如订购关系记录、消费记录等。
                    （2）日志信息，指您使用我们的服务和产品时，我们的系统可能通过信令、数据通道或其他方式自动采集的记录信息，包括：
                    1）设备或软件信息，例如您的电信设备接入我们网络和服务器或使用我们业务时所提供的配置信息、您的IP地址和电信设备所用的版本和设备识别码等；
                    2）在使用我们网络、服务时接入互联网的信息，例如您使用的操作系统、浏览器类型和语言、浏览的IP地址，以及您在使用我们服务时产生的记录信息；
                    3）有关您曾使用的电信应用（APP）和其他软件的信息，以及您曾经使用该等电信应用和软件的信息；
                    4）您通过我们的通信服务进行通信的信息，例如曾通信的号码，以及通信时间、数据和时长等。
                    （3）位置信息，指您连接我们网络，或使用我们基于位置提供的相关服务时，收集的有关您的位置的信息。
                    （4）其他经过您授权收集和使用的信息。
                  </p>
                  <p>
                    二、我们如何使用信息 我们可能将在向您提供服务的过程中收集的信息用作下列用途：
                    1.向您提供服务，或将我们的最新产品、服务及活动预告及时通知您；
                    2.在我们提供服务时，用于身份验证、客户服务、安全防范（包括防范打击通讯信息诈骗）、存档和备份用途，确保我们向您提供的产品和服务的安全性；
                    3.用于审计、数据分析和网络质量分析等内部目的，帮助我们设计新服务，优化我们的网络，改善我们现有的服务；
                    4.使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您做出其他方面的回应；
                    5.向您提供与您更加相关的产品推荐； 6.评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；
                    7.软件认证或管理软件升级； 8.请您参与有关我们产品和服务的调查。
                    在符合相关法律法规的前提下，为了让您有更好的体验、改善我们的服务或您同意的其他用途，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。
                  </p>
                  <p>
                    三、我们可能分享的信息 除以下情形外，未经您同意，我们不会与任何第三方分享您的个人信息：
                    1.我们可能将您的个人信息与我们的合作伙伴分享，用作下列用途： 1）向您提供我们的服务；
                    2）实现“我们如何使用信息”部分所述目的； 3）履行我们在各类服务协议或本《个人信息保护政策》中的义务；
                    4）在经过处理后无法识别特定个人且不能复原的前提下，用于大数据的开发与利用；
                    5）设计、维护和改善我们的服务。
                    如我们就上述用途，与任何第三方分享您的个人信息，我们将努力确保第三方在使用您的个人信息时遵守本《个人信息保护政策》，并符合国家有关法律法规的保密和安全要求。
                    随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，如涉及到您个人信息的转移，我们会要求新的持有您个人信息的公司、组织继续受本《个人信息保护政策》的约束，否则我们将要求该公司、组织重新向您征求授权同意。
                    2.我们还可能为以下需要而保留、保存或披露您的个人信息： 1）遵守适用的法律法规；
                    2）遵守法院命令或其他法律程序的规定； 3）遵守相关政府机关的要求；
                    4）为维护社会公共利益或相关方合法权益，国家法律法规允许的其他用途。
                  </p>
                  <p>
                    四、您如何访问和控制个人信息
                    如果您发现我们违反国家法律法规的规定或者与您的约定，收集、使用您的个人信息或收集、存储的信息有错误，根据信息的类型不同，您可以通过营业厅办理、拨打客服电话、登陆网站或APP办理等途径，访问、更新、更正或删除您的注册信息和其他个人信息。
                    在访问、更新、更正和删除信息时，我们可能会要求您进行身份验证，以保障账户安全，并且对于特定信息的更新、更正和删除，可能会导致您无法使用我们的对应的服务和产品。
                  </p>
                  <p>
                    五、信息安全
                    我们非常重视您的个人信息安全。我们仅在本《个人信息保护政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
                    我们会严格按照法律规定建立信息安全保障制度，采取技术措施和其他必要措施防止信息泄露、丢失、不当使用、未经授权阅览或披露等，从而保障您的个人信息安全。
                    但请您理解，由于技术的限制以及可能存在的各种恶意手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统、设备和通信网络，有可能因我们可控范围外的因素而出现问题，比如下列情形下的信息泄露或滥用与我们无关：
                    1.当您使用某些我们的产品、服务时，您分享或披露的个人信息和内容会被其他用户看到，他们可能会阅读、收集或使用您的个人信息。对于您在上述情形中所产生的个人信息泄露、滥用或其他后果与我们无关。
                    2.如果您或其他任何使用共享的用户登录到第三方的设备，则您的个人信息可能会被下载到该第三方设备上，下载到该设备上的个人信息导致的信息泄露、滥用或其他后果与我们无关。
                    3.您在使用第三方的服务或产品时，所填写的手机号码、联系方式等信息，由此类信息导致的手机号码、联系方式泄露、滥用或其他后果与我们无关。
                    4.其他非我们的原因造成的信息泄露、滥用等后果与我们无关。
                  </p>
                  <p>
                    六、未成年人使用我们的服务
                    我们将严格按照《中华人民共和国未成年人保护法》的法律法规要求，对未成年人个人信息予以保护。
                  </p>
                  <p>
                    七、其他相关事项
                    除某些特定服务外，我们所有的服务均适用本《个人信息保护政策》。这些特定服务将适用特定的个人信息保护政策。针对某些特定服务的特定个人信息保护政策，将更具体地说明我们在该特定服务中如何使用您的个人信息。该特定服务的个人信息保护政策构成本《个人信息保护政策》的一部分。如特定服务的个人信息保护政策与本《个人信息保护政策》有不一致之处，适用该特定服务的个人信息保护政策。
                    我们可能对个人信息保护政策进行修改和更新。涉及重大变更或者可能影响您的权利义务的修改，我们将在官方网站上发布公告或以其他合适的方式告知。在该种情况下，若您继续使用我们的服务，即表示您同意我们修订后的个人信息保护政策。
                  </p>
                </div>
              </Mask>
            </div>

            <Image src={imgBtn} onClick={onFinish} />
          </>
        }
      >
        <Form.Item label="收件姓名">
          <Input value={recvName} onChange={setRecvName} placeholder="收件姓名（已加密）" />
        </Form.Item>
        <Form.Item label="收件手机号">
          <Input
            value={recvPhone}
            onChange={setRecvPhone}
            placeholder="收件真实手机号（已加密）"
            clearable
            maxLength={11}
            minLength={11}
          />
        </Form.Item>
        <Form.Item
          trigger="onConfirm"
          label="城市"
          arrow
          onClick={() => {
            setVisible(true)
          }}
        >
          <Cascader
            value={addrList}
            onConfirm={setAddrList}
            options={areas}
            visible={visible}
            onClose={() => {
              setVisible(false)
            }}
          >
            {items => {
              if (items.every(item => item === null)) {
                return '请选择您所在城市'
              } else {
                return items.map(item => item?.label ?? '请选择您所在城市').join('')
              }
            }}
          </Cascader>
        </Form.Item>
        <Form.Item label="详细地址">
          <Input
            value={recvAddrDetail}
            onChange={setRecvAddrDetail}
            placeholder="街道/镇村/小区/写字楼门牌号"
            clearable
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.recvName !== curValues.recvName ||
            prevValues.recvPhone !== curValues.recvPhone ||
            prevValues.recvAddrDetail !== curValues.recvAddrDetail
          }
        >
          {recvName.length > 0 && recvPhone.length === 11 && recvAddrDetail.length > 0 ? (
            <>
              <Form.Item label="开户姓名">
                <Input value={openName} onChange={setOpenName} placeholder="请填写真实姓名（已加密）" clearable />
              </Form.Item>
              <Form.Item label="开户身份证">
                <Input
                  value={openIdCard}
                  onChange={setOpenIdCard}
                  placeholder="请填写真实身份证（已加密）"
                  clearable
                  maxLength={18}
                  minLength={15}
                />
              </Form.Item>
            </>
          ) : null}
        </Form.Item>
      </Form>
    </>
  )

  return (
    <div style={{ margin: '0 8px 8px 8px' }}>
      <NavBar onBack={() => navigate('/')}>{(prod && prod.prodName) || ''}</NavBar>
      {form}
      {prod && prod.prodImages && prod.prodImages.length > 1
        ? prod.prodImages
            .slice(1)
            .map(img => (
              <Image
                key={img}
                width="100%"
                src={`${HTTP_URL.ROOT}/web/v1/static/file?filePath=${encodeURIComponent(img)}`}
              />
            ))
        : null}
    </div>
  )
}

export default Index
