import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as appActions } from '../redux/app'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import { Image, Swiper, TabBar } from 'antd-mobile'
import { HTTP_URL } from '../infra/constants'
import { AppOutline, StarOutline, TruckOutline } from 'antd-mobile-icons'
import { apiBanner } from '../api/order'
import Prod from '../components/Prod'
import Buy from '../components/Buy'
import Delivery from '../components/Delivery'
import Service from '../components/Service'
import RedirectTo from '../components/RedirectTo'
import './App.css'
import globals from '../data/globals'

const App = () => {
  const bannerImages = useSelector(state => state.app.bannerImages)
  const dispatch = useDispatch()

  const pathname = window.location.pathname
  console.log('pathname=', pathname)

  const [searchParams] = useSearchParams()
  if (searchParams.get('id')) {
    window.localStorage.setItem('simId', searchParams.get('id'))
    console.log('local:', window.localStorage.getItem('simId'))
  }
  if (searchParams.get('clickid') && (pathname === '' || pathname === '/' || pathname === '/prod')) {
    window.localStorage.setItem('clickid', searchParams.get('clickid'))
    console.log('local clickid:', window.localStorage.getItem('clickid'))
  }

  useEffect(() => {
    onQueryBanner()
  }, [])

  const onQueryBanner = () => {
    apiBanner().then(resp => {
      if (!resp.data.data) return
      console.log(resp.data.data)
      const bannerImages = (resp.data.data || []).filter(it => it.k === 'HEADER_IMAGE').map(it => it.v)
      console.log(bannerImages)
      globals.bannerImages = bannerImages
      dispatch(appActions.setBannerImages(globals.bannerImages))
    })
  }

  // nav
  const navigate = useNavigate()

  return (
    <div className="app">
      <div>
        {bannerImages.length > 0 ? (
          <Swiper autoplay>
            {(bannerImages || []).map(img => (
              <Swiper.Item key={img}>
                <Image src={`${HTTP_URL.ROOT}/web/v1/static/file?filePath=${encodeURIComponent(img)}`} />
              </Swiper.Item>
            ))}
          </Swiper>
        ) : null}
      </div>
      <div>
        <TabBar
          defaultActiveKey="/prod"
          onChange={k => {
            dispatch(appActions.setBannerImages(globals.bannerImages))
            if (k === '/service') window.location.href = 'https://work.weixin.qq.com/kfid/kfc560a6e94de86e408'
            else navigate(k)
          }}
        >
          <TabBar.Item key="/prod" icon={<StarOutline />} title="流量套餐" />
          <TabBar.Item key="/delivery" icon={<TruckOutline />} title="订单查询" />
          <TabBar.Item key="/service" icon={<AppOutline />} title="客户服务" />
        </TabBar>
      </div>

      <div className="body">
        <Routes>
          <Route exact path="/" element={<Prod />} />
          <Route exact path="/prod" element={<Prod />} />
          <Route exact path="/delivery" element={<Delivery />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/buy" element={<Buy />} />
          <Route exact path="/rta" element={<RedirectTo />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
