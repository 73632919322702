const { baseUrl } = window['runtime-config']

const HTTP_URL = Object.freeze({
  ROOT: baseUrl,
  TOKEN_RENEW: '/admin/v1/user/token-renew',
})

// aes
const CONST_AES = Object.freeze({
  KEY: '$1qaz2wsx3edc4r$',
  IV: '$1qaz2wsx3edc4r$',
})

const MAP_ROLE = {
  ADMIN: '管理员',
  STAFF: '员工',
  AGENT: '分销员',
}

const MAP_DELIVERY_STATUS = {
  0: '未配送',
  1: '配送中',
  9: '配送结束',
}

export { HTTP_URL, CONST_AES, MAP_ROLE, MAP_DELIVERY_STATUS }
