import ajaxApi from '../infra/axios-settings'

const formEvent = payload => {
  return ajaxApi.post('/api/v1/order-douyin-trace', payload)
}

const debug = payload => {
  return ajaxApi.post('/api/v1/douyin-debug', payload)
}

export { formEvent, debug }
